.fixed {
    max-width: 80vw;
    max-height: 80vh;
    overflow: auto;
    .preview {
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
        box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
        width: 29.7cm;
        height: 21cm;
        display: flex;
        font-size: 7px;
    }
}
