// Here you can add other styles
.app-header.navbar .navbar-brand {
	background-size: 135px auto;
}

.topFiltre {
	margin-bottom: 10px;
	.filtre {
		width: 120px;
	}

	justify-content: space-evenly;
	flex-wrap: wrap;
	display: inline-flex;
}
.clicker{
	cursor: pointer;
}

.modal-brouillon{
	margin: 10px;
	display: flex;
	flex-wrap: wrap;
}

.brouillon-part{
	width: 50%;
	text-align: center;
}
.btn-primary-perso {
	color: #fff;
	background-color: #20a8d8;
	border-color: #20a8d8;
}

.navbar {

	.form-inline {
		i {
			color: $navbar-color;
		}

		.form-control {
			min-width: 250px;
			color: $navbar-color;
			border: 0;

			&::placeholder {
				color: $navbar-color;
			}
		}

		.form-control::-webkit-input-placeholder {
			color: $navbar-color;
		}

		.form-control:-moz-placeholder {
			color: $navbar-color;
		}

		.form-control::-moz-placeholder {
			color: $navbar-color;
		}

		.form-control:-ms-input-placeholder {
			color: $navbar-color;
		}
	}
}

.rt-resizer{
	z-index: 0 !important;
}

.alignBtn{
	display: flex;
	flex-direction: row;
	margin: auto;
}

.react-pdf__Page__canvas{
	margin: auto;
}




.sidebar {
	.sidebar-header {
		.img-avatar {
			width: 80px;
			margin: 20px auto 10px;
			border-radius: 50em;
		}

		> .btn-group {
			margin-top: 10px;
		}

		.btn-link {
			color: $text-muted;

			&:hover {
				color: $sidebar-color;
				text-decoration: none;
			}
		}
	}
}

.app-header.navbar .navbar-brand {
	background-size: 180px auto;
}

.card-header .card-actions a, .card-header .card-actions button {
	display: block;
	float: left;
	width: auto;
	min-width: 50px;
	padding: 0.75rem 0.75rem;
	margin: 0px ;
	text-align: center;
	border: 0;
	border-left: 1px solid #d1d4d7;
}

.card-header-contract {
	padding-top: 16px;
}

.card-header-contract-50 {
	padding-top: 16px;
	min-width: 225px;
}

.card-header-contract-material{
	padding-top: 16px;
	height: 60px !important;
}

.title-contract {
	width: 400px;
	margin-right: 50px;
}

.card-actions {
	padding-top: 7px;
	padding-right: 7px;
	display: flex;
	flex-wrap: wrap;
}

.group-button-contract {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.breadcrumb {
	background: #FFF;
	//border-bottom: 1px solid #e3e8ec;
	//margin-bottom: 1.5rem;
}

.jumbotron {
	padding: 0;
}

.list-group-item {
	text-align: left;
}

.field-info {

	display: flex;
	flex-direction: row;
	padding: 5px 0;

	.label {
		width: 200px;
	}

	.value {

		flex: 1;
	}
}

.form-horizontal.form-quote {

	.form-group {

		input[type=number] {
			max-width: 200px;
		}
	}

	.card-title {
		margin: 1rem 0;

		padding: 1rem 0;
	}
}

//A sortir dans une feuille dédié à la commnande
.detail-financement {

	font-size: 14px;

	.row {
		padding: 10px 0;
	}

	.col-md-4 {
		text-align: right;
	}


}


.form-vertical {

	.form-group {

		flex-direction: column;

		.col-md-3, .col-md-9 {
			flex: 1;
			max-width: 100%;
		}
	}
}

.DateInput_input {
	padding: 7px 12px 7px !important;
}

.react-bootstrap-daterangepicker-container {
	display: block !important;
}

.select2Search {
	.Select-control {
		border-radius: 0;
		height: 44px;

		.Select-multi-value-wrapper {
			font-size: 20px;
		}
	}
}


.aside-menu {

	.list-group {
		.list-group-item {

			border: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			margin-bottom: 0;

		}
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 90%;
	}

	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 100%;
	}

	.card-header-contract-50 {

		width: auto;
	}


}

	.card-expand {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 9998;
		padding: 0;
		margin: 0;
	}

	.reduce {
		color: rgba(0, 0, 0, 0.54);
		font-size: 20px;
		padding: 5px 10px;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background-color: rgba(182, 182, 182, 0.1);
		position: fixed;
		z-index: 9999;
		top: 60px;
		right: 20px;
	}

	.reduce:hover {
		color: rgba(0, 0, 0, 1);
		background-color: rgba(182, 182, 182, 0.6);
	}

	.date-picker-field {
		.react-datepicker-wrapper {
			width: 100%;

			input {
				width: 100%;
			}
		}
	}

	.react-time-picker__wrapper {
		border: none !important;
	}

	.bordure-entretien-tech {
		border: 1px solid #CCCCCC;
		border-radius: 4px;
		font-size: 17px;
		width: 80px;
		height: 36px;
		background-color: white;
		display: flex;
		justify-content: center;
		padding-top: 5px;
	}

	.number-entretien-tech {
		width: 50px;
		display: flex;
		justify-content: center;
	}

	.compteur-entretien-tech {
		display: flex;
		margin: 8px 0 12px 20px;
		align-items: center;
	}

	.bordure-select-entretien-tech {
		width: 150px;
		display: flex;
		margin: 6px;
		justify-content: center;
	}

	.filtre-entretiens {
		display: flex;
		flex-wrap: wrap;
		margin: 15px 0;
		padding: 0 15px;
		justify-content: space-evenly;
	}

	/*******************************************************************************/


.date-dashboard-field {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-right: 10px;
}

.text-date-field {
	display: flex;
	flex-direction: row;
	margin-right: 10px;
	margin-top: 5px
}

.graph-tie {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	min-height: 400px;
}

.table-lign {
	background-color: white;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	border: 1px solid #CCCCCC;
	border-radius: 6px;
	padding: 5px 15px 5px 15px;
	font-size: 20px;
	height: 40px;
	width: 100%;
}

/*.table-margin-0, .table-margin-1, .table-margin-2, .table-margin-3, .table-margin-4  {
	margin-bottom: 30px ;
}*/

.value-sold-essential {
	color: #8CD494;
}

.value-sold-confort {
	color: #518CC4;
}

.value-cancelled-essential {
	color: #FF9B85;
}

.value-cancelled-confort {
	color: #FFD97D;
}

.name-products {
	width: 300px;
	display: flex;
	justify-content: center;
}

.case-products {
	width: 50px;
	text-align: center;
}

.graph-types {
	border: 1px solid #CCCCCC;
	border-radius: 12px;
	padding: 5px;
	margin-top: 12px;
	background-color: white;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
}

.graph-types-equipments {
	margin-top: 12px;
	width: 100%;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.title-types {
	font-size: 22px;
	font-weight: bold;
	padding-left: 25px;
}

.graph-margin-right {
	margin-right: 15px;
}

.graph-title {
	font-size: 28px;
	font-weight: bold;
	padding-left: 35px;
	width: 500px;
}

.body-color {
	background-color: #F8F9FA;
	padding: 10px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
}

.titleDashboard {
	padding: 10px;
	font-size: 35px;
	font-weight: bold;
}

.graph-align {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	margin-top: 20px;
	height: 30px;
}

.graph-selector {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	height: 50px;
}

.graph-select-field {
	width: 200px;
	z-index: 1;

}

.graph-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.header-button-contract {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.main-graph-filters {
	display: flex;
	flex-direction: row;
	margin-right: 50px;
	justify-content: flex-end;
}

.graph-filter {
	margin-left: 105px;
	margin-bottom: 5px;
}

.filters-less {
	margin-top: 8px;
	border: 1px solid #CCCCCC;
	border-radius: 4px;
	width: 200px;
	height: 36px;
	padding-top: 5px;
	padding-left: 10px;
	background-color: white;
}

.graph-filter .Select-control {
	box-shadow: 1px 1px 4px #555;
}

.group-filter-less {
	margin-left: 100px;
}

group-filter-clearValue {
	position: relative;
}

.button-clear-value {
	z-index: 2;
	position: relative;
	bottom: 30px;
	left: 155px;
	border: none;
	background-color: white;
	outline: none!important;
	border-radius: 20px;
}

.button-clear-value:hover {
	color: red;
}

.graph-margin-right {
	margin-right: 20px;
}

.box-shadow {
	box-shadow: 1px 1px 7px #555;
}

@media only screen and (max-width: 1750px) {
	.graph-header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.main-graph-filters {
		display: flex;
		flex-direction: row;
		margin-right: 5px;
		flex-wrap: wrap;
		justify-content: flex-start;
		height: 80px;
	}

	.graph-filter {
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.group-filter-less {
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.graph-tie {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		width: 100%;
	}

	.graph-types {
		justify-content: space-around;
		width: 100%;
	}

	.graph-align {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		height: 30px;
		margin-top: 0px !important;
	}

	.graph-title {
		font-size: 25px;
		font-weight: bold;
		padding-left: 20px;
		width: auto;
		display: flex;
		flex-wrap: nowrap;
		margin-top: -5px;
		margin-right: 10px;
		min-width: 316px;
	}

	.graph-selector {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		height: 50px;
		margin-top: 10px;
	}

	.title-types {
		font-size: 20px;
		font-weight: bold;
		padding-left: 25px;
		display: flex;
	}

	.button-clear-value:hover {
		color: black;
	}

	.button-clear-value:active {
		color: red;
		outline: black;
	}

	.table-lign {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-around;
		width: auto;
		border: 1px solid #CCCCCC;
		border-radius: 6px;
		font-weight: 1000;
	}

	.graph-margin-right {
		margin-right: 0px;
	}

	.graph-types-equipments {
		height: 500px;
	}
}

@media only screen and (max-width: 991px) {
	.contract-form-card {
		min-width: 500px;
	}
}

@media only screen and (max-width: 500px) {
	.contract-form-card {
		min-width: 255px;
	}

}

.button-text {
	color: #faf3f3 !important;
	font-weight: bold;
	border-radius: 2px;
	border: none!important;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)
}

.button-export {
	background-color: #876bfd !important;
}
.button-export:hover {
	background-color: #ab98ff !important;
}

.button-create {
	background-color: #00bcd4 !important;
}
.button-create:hover {
	background-color: #60cad9 !important;
}

.button-sync {
	background-color: #66bb6a !important;
}
.button-sync:hover {
	background-color: #84c987 !important;
}

.button-remove {
	background-color: rgba(252, 60, 60, 0.85) !important;
}
.button-remove:hover {
	background-color: #ff8888 !important;
}

.button-archive {
	background-color: #fac829 !important;
}
.button-archive:hover {
	background-color: #ffda72 !important;
}

.button-cancel {
	background-color: rgba(157, 157, 157, 0.85) !important;
}
.button-cancel:hover {
	background-color: #bdbdbd !important;
}

.button-shift {
	margin-right: 50px !important;
}

.inputCheckBox {
	width: 22px;
	height: 22px;
	margin: 6px
}

.inputCheckBox:hover {
	cursor: pointer;
}

.user-name {
	display: flex;
	margin-right: 15px;
	text-transform: capitalize;
	font-weight: bold;
	padding-left: 7px !important;
}

.user-name:hover {
	border-bottom:solid 1px !important;
}

.user-name-icone {
	margin: 0 10px 0 30px;
	padding-top: 1px;
}

.contract_filter {
	border: 1px solid #CCCCCC;
	border-radius: 4px;
	font-size: 17px;
	width: 100px;
	height: 36px;
	background-color: white;
	display: flex;
	justify-content: center;
	padding-top: 5px;
}

.cell-montant-contrat {
	display: flex;
	justify-content: space-between;
	padding-left: 5px;
	padding-right: 20px;
}

.cell-contrat-bold {
	font-weight: bold;
}

.group-filters {
	display: flex;
	width: 500px;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 10px;
	justify-content: center;
}

.filters-contract {
	margin: 5px !important;
}
.exchange{
	cursor: pointer;
	border: none;
	background-color: transparent;
}
.divExchange{
	display: flex;
	align-items: end;
	margin-bottom: 12px;
}

.shadow-header {
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2)
}

#field-form::placeholder {
	color: #d0d0d0;
}

.filter-wallet {
	width: 150px;
	margin: 15px;
}

.icone-button-text {
	margin-right: 8px;
}

.button-all-synced {
	font-size: 22px;
	font-weight: bold;
	width: 100%;
	height: 70px;
	justify-content: center;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
	border-radius: 2px;
}

.border-card {
	min-width: 205px;
	text-decoration: none !important;
	border: none !important;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.shadow-card {
	margin-bottom: 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2)
}

.rt-td {
	text-align: center !important;
}

.rt-resizable-header-content {
	font-weight: bold;
	font-size: 16px;
	height: 30px;
	text-align: center !important;
}

.rt-resizable-header {
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2) !important
}

.cross-delete:hover {
	color: #ea0303;
	margin-bottom: 4px;
}

.cross-delete {
	color: #a60303;
	margin-bottom: 4px;
}

.button-reload-filters {
	display: flex;
	z-index: 50;
	position: absolute;
	justify-content: center!important;
	align-items: center!important;
	outline: none!important;
	left: 30px;
	top: 110px;
	font-size:24px;
	cursor:pointer;
	width:55px;
	height:55px;
	border: none;
	background-color: #fff;
}

@media only screen and (max-width: 876px) {
	.button-reload-filters {
		top: 150px;
	}
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 700px;
	}
}

@media (min-width: 576px) {
	.col {
		flex-grow: 1;
	}
}

.cell-cursor {
	cursor: pointer;
}

.field-date-dashboard {
	box-shadow: 1px 1px 4px #555;
	text-align: center;
}

.field-filters {
	border-radius: 4px;
	border: 1px solid #ccc !important;
}

.button-border {
	border-radius: 3px;
}

.text-date-field-Dashboard {
	align-items: center;
}

.testtest1010 {
	background-color: #6f42c1;
	color: #00bcd4;
}

.field-modal-sign-validate {
	color: #7ab800;
}

.field-modal-sign-missing {
	color: #d22336;
}

.custom-file-label::after {
	border-radius: 0 4px 4px 0;
}

.title-card-form {
	padding-top: 7px;
}

.title-card-header {
	border: 1px solid #ccc ;
}

@media only screen and (max-width: 600px) {
	.col-popup-annul {
		font-size: 11px;
	}
}

@media only screen and (max-width: 500px) {
	.col-popup-annul {
		font-size: 9px;
	}
}

@media only screen and (max-width: 450px) {
	.col-popup-annul {
		font-size: 8px;
	}
}
//
//.react-datepicker-wrapper {
//
//}
//
//.react-datepicker-wrapper,
//.react-datepicker__input-container,
//.react-datepicker__input-container input {
//	width: 150px!important;
//	border-color: #dadada;
//	border-radius: 5px;
//	box-shadow: 1px 1px 5px #555;
//}
